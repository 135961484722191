@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap');

.nuvo-tailwind {
  @apply text-gray-650;
  @apply text-base;

  font-family: var(--globals-font-family);
  color: var(--globals-text-color);
  font-size: 16px;

  .text-color-primary {
    color: var(--text-color-primary);
  }

  .text-color-secondary {
    color: var(--text-color-secondary);
  }

  [role='gridcell'] {
    padding: 0px;
  }

  [role='gridcell'][aria-selected='true'] > .cell-viewer {
    box-shadow: inset 0 0 0 2px var(--rdg-selection-color);
  }

  .nuvo-invisible {
    visibility: hidden;
  }

  /* NOTE: it's from ui-kit. we haven't use ui-kit yet in sdk */
  .caption {
    font-size: 12px;
    line-height: 1.43;
  }
}

.nuvo-ReactModal__Body--open {
  overflow: hidden;
}

/* NOTE: it's from ui-kit. */
:root {
  --layer-overlay: 300;
  --nuvo-white: #ffffff;
  --nuvo-neutral-900: #2d2d2d;
  --shadow-bottom: 0px 7px 15px 0px rgba(27, 80, 113, 0.1);
}

.nuvo-popover {
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: column;
}

.nuvo-popover__anchor {
  display: inline-flex;
  flex-direction: column;
  border: 0;
  outline: 0;
  padding: 0;
  background-color: transparent;
}

.nuvo-popover__content {
  background-color: var(--nuvo-white);
  color: var(--nuvo-neutral-900);
  box-shadow: var(--shadow-bottom);
  border-radius: 4px;
  padding: 12px 16px;
  z-index: var(--layer-overlay);
  outline: none;
  overflow-y: auto;
}
