.nuvo-tailwind {
  .handsontable {
    font-family: var(--globals-font-family);
  }

  .handsontable .htDimmed {
    @apply text-blue-dark-900;
  }

  .handsontable td.htDimmed {
    @apply text-blue-dark-900;
  }

  .ht_clone_top {
    z-index: 20 !important;
  }

  .ht_clone_left:not(.handsontableInputHolder) {
    z-index: 0 !important;
  }

  .ht_clone_top_left_corner {
    z-index: 20 !important;
  }

  .ht_clone_top .ht__highlight {
    @apply !bg-gray-50;
  }

  .ht_clone_top_left_corner div.wtHolder .htCore,
  col.rowHeader {
    width: 60px !important;
  }

  .spread-data-sheet
    .ht_clone_left
    .wtHolder
    .wtHider
    div.wtSpreader
    .htCore
    tbody
    tr
    th {
    @apply flex items-center justify-center text-sm;
  }

  .spread-data-sheet
    .handsontable.wt-full-width
    .ht_master.handsontable
    .wtHider {
    width: 100% !important;
  }

  .spread-data-sheet
    .handsontable.wt-full-width
    .ht_master.handsontable
    .wtHider
    .wtSpreader {
    width: 100% !important;
  }

  .spread-data-sheet
    .ht_clone_left
    .wtHolder
    .wtHider
    div.wtSpreader
    .htCore
    tbody
    tr
    th
    div {
    height: 31px !important;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    @apply py-0;
  }

  .ht_clone_left .wtHolder table {
    box-shadow: 2px 0 5px -2px hsl(0deg 0% 53% / 30%);
  }

  .wtBorder.corner {
    display: none;
  }

  thead th:first-child {
    @apply bg-gray-50;
  }

  .handsontable td {
    padding: 0px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    border-color: #dddddd;
    @apply text-sm;
  }

  .handsontable thead th:first-child {
    border-bottom: 1px solid #dddddd;
  }

  .ht_clone_top th {
    @apply !border-gray-250;
  }

  .ht_clone_top .wtHolder .wtHider .wtSpreader .htCore th:first-child,
  .ht_clone_top_left_corner .wtHolder .wtHider .wtSpreader .htCore th {
    border-top: none !important;
  }

  .ht_clone_top .wtHolder .wtHider .wtSpreader .htCore th:first-child,
  .ht_clone_top_left_corner
    .wtHolder
    .wtHider
    .wtSpreader
    .htCore
    th:first-child {
    border-left: none !important;
    padding-top: 1px;
  }

  .ht_clone_top .wtHolder .wtHider .wtSpreader .htCore th {
    border-top: none !important;
  }

  .ht_clone_left .wtHolder .wtHider .htCore th {
    border-left: none !important;
    padding-left: 5px;
    @apply !border-gray-250 bg-white;
  }

  .ht_master .wtHolder .wtHider .htCore th {
    border-left: none !important;
    padding-left: 1px;
  }

  td.default-cell.currentRow {
    @apply !bg-blue-dark-50;
  }

  /* spread-data-sheet */

  .spread-data-sheet #hot-display-license-info {
    display: none;
  }

  .spread-data-sheet .handsontable {
    font-family: var(--globals-font-family);
  }

  .spread-data-sheet .handsontable td .ht-cell {
    padding: 0px 16px;
  }

  .spread-data-sheet .handsontable td {
    padding: 0px 16px !important;
    border-color: #dddddd;
    @apply items-center !text-left;
  }

  .spread-data-sheet
    .handsontable
    .hide-border-last-row
    .htCore
    tr:last-child
    td {
    @apply border-b-0;
  }

  .spread-data-sheet
    .handsontable
    .hide-border-last-row
    .ht_clone_left
    tr:last-child
    th {
    border-bottom: transparent;
  }

  .spread-data-sheet .handsontable .ht_master .wtHolder::-webkit-scrollbar {
    @apply !h-2 !w-2;
  }

  .spread-data-sheet
    .handsontable
    .ht_master
    .wtHolder::-webkit-scrollbar-track {
    background-color: #dfe2e4;
    @apply rounded-medium !w-1 bg-clip-padding;
  }

  .spread-data-sheet
    .handsontable
    .ht_master
    .wtHolder::-webkit-scrollbar-thumb {
    @apply rounded-medium !bg-gray-300;
  }

  .spread-data-sheet ::-webkit-scrollbar-thumb {
    background-color: #818b99;
    border: 0px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
  }

  .spread-data-sheet .ht_clone_left .wtHolder::-webkit-scrollbar {
    @apply !h-2 !w-2;
  }

  .spread-data-sheet .ht_clone_left .wtHolder::-webkit-scrollbar-track {
    background-color: #dfe2e4;
    @apply rounded-medium !mr-2 bg-clip-padding;
  }

  .spread-data-sheet .ht_clone_left .wtHolder::-webkit-scrollbar-thumb {
    @apply rounded-medium !bg-gray-300;
  }

  .spread-data-sheet .ht_clone_left .wtHolder .wtHider {
    box-sizing: border-box !important;
    @apply !mb-8;
  }

  .spread-data-sheet.not_firefox .ht_clone_top {
    width: 100% !important;
  }

  .spread-data-sheet.not_firefox
    .handsontable.wt-full-width
    .ht_clone_top
    .wtHolder,
  .spread-data-sheet.not_firefox
    .handsontable.wt-full-width
    .ht_clone_top
    .wtHolder
    .wtHider,
  .spread-data-sheet.not_firefox
    .handsontable.wt-full-width
    .ht_clone_top
    .wtHolder
    .wtSpreader,
  .spread-data-sheet.not_firefox
    .handsontable.wt-full-width
    .ht_clone_top
    .wtHolder
    .htCore {
    width: 100% !important;
  }

  .spread-data-sheet.not_firefox
    .handsontable.add-space-scrollbar-w
    .ht_clone_top {
    width: calc(100% - 8px) !important;
  }

  .spread-data-sheet.not_firefox
    .handsontable.add-space-scrollbar-w
    .ht_clone_top
    .wtHolder {
    width: 100% !important;
  }

  .spread-data-sheet.not_firefox .handsontable .ht_clone_left,
  .spread-data-sheet.not_firefox .handsontable .ht_clone_left .wtHolder {
    height: 100% !important;
  }

  .spread-data-sheet.not_firefox
    .handsontable.add-space-scrollbar-h
    .ht_clone_left,
  .spread-data-sheet.not_firefox
    .handsontable.add-space-scrollbar-h
    .ht_clone_left
    .wtHolder {
    height: calc(100% - 4px) !important;
  }

  .spread-data-sheet #hotInstance .ht_clone_top_left_corner,
  .spread-data-sheet #hotInstance .ht_clone_left td {
    box-shadow: 2px 0 5px -2px hsl(0deg 0% 53% / 30%);
  }

  .spread-data-sheet .hide-border-last-column .ht_clone_top th:last-child,
  .spread-data-sheet .hide-border-last-column .ht_clone_top td:last-child,
  .spread-data-sheet
    div.hide-border-last-column
    .handsontable.ht_master
    tr
    td:last-child {
    border-right-color: transparent !important;
    border-right-width: 0 !important;
  }

  .spread-data-sheet tr td.default-cell,
  .spread-data-sheet tr td.hover-row {
    @apply text-blue-dark-900 cursor-pointer text-sm;
  }

  .spread-data-sheet.read-only tr td.default-cell {
    @apply text-gray-560;
  }

  .spread-data-sheet.read-only tr td {
    @apply !cursor-auto;
  }

  .spread-data-sheet
    .ht_clone_left
    .wtHolder
    .wtHider
    div.wtSpreader
    .htCore
    tbody
    tr
    th {
    text-indent: -1px;
  }

  .spread-data-sheet .ht_master .wtHolder .wtSpreader table {
    width: 100%;
  }

  .spread-data-sheet .ht_left .wtHolder .wtSpreader {
    width: 100%;
  }

  .spread-data-sheet tr .hover-row,
  .spread-data-sheet tr .hover-selected-row,
  .spread-data-sheet tr .selecting-row {
    @apply cursor-pointer text-sm;
  }

  .spread-data-sheet tr td div {
    padding-top: 7px;
    box-sizing: border-box;
  }

  .spread-data-sheet
    .handsontable
    tr
    td.selecting-row-and-hovering.selecting-row {
    @apply text-blue-dark-900 cursor-pointer;
  }

  /* sheet-preview */
  .sheet-preview .handsontable {
    font-family: var(--globals-font-family);
  }

  .sheet-preview .handsontable td .ht-cell {
    padding: 0px 16px;
  }

  .sheet-preview .handsontable td {
    padding: 0px 16px !important;
    border-color: #dddddd;
    @apply items-center !text-left;
  }

  .sheet-preview .handsontable .hide-border-last-row .htCore tr:last-child td {
    @apply border-b-0;
  }

  .sheet-preview
    .handsontable
    .hide-border-last-row
    .ht_clone_left
    tr:last-child
    th {
    border-bottom: transparent;
  }

  .sheet-preview .handsontable .ht_master .wtHolder::-webkit-scrollbar {
    @apply !h-2 !w-2;
  }

  .sheet-preview .handsontable .ht_master .wtHolder::-webkit-scrollbar-track {
    background-color: #dfe2e4;
    @apply rounded-medium !w-1 bg-clip-padding;
  }

  .sheet-preview .handsontable .ht_master .wtHolder::-webkit-scrollbar-thumb {
    @apply rounded-medium !bg-gray-300;
  }

  .sheet-preview ::-webkit-scrollbar-thumb {
    background-color: #818b99;
    border: 0px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
  }

  .sheet-preview .ht_clone_left .wtHolder::-webkit-scrollbar {
    @apply !h-2 !w-2;
  }

  .sheet-preview .ht_clone_left .wtHolder::-webkit-scrollbar-track {
    background-color: #dfe2e4;
    @apply rounded-medium !mr-2 bg-clip-padding;
  }

  .sheet-preview .ht_clone_left .wtHolder::-webkit-scrollbar-thumb {
    @apply rounded-medium !bg-gray-300;
  }

  .sheet-preview .ht_clone_left .wtHolder .wtHider {
    box-sizing: border-box !important;
    @apply !mb-8;
  }

  .sheet-preview.not_firefox .ht_clone_top {
    width: 100% !important;
  }

  .sheet-preview.not_firefox
    .handsontable.wt-full-width
    .ht_clone_top
    .wtHolder,
  .sheet-preview.not_firefox
    .handsontable.wt-full-width
    .ht_clone_top
    .wtHolder
    .wtHider,
  .sheet-preview.not_firefox
    .handsontable.wt-full-width
    .ht_clone_top
    .wtHolder
    .wtSpreader,
  .sheet-preview.not_firefox
    .handsontable.wt-full-width
    .ht_clone_top
    .wtHolder
    .htCore {
    width: 100% !important;
  }

  .sheet-preview.not_firefox .handsontable.add-space-scrollbar-w .ht_clone_top {
    width: calc(100% - 8px) !important;
  }

  .sheet-preview.not_firefox
    .handsontable.add-space-scrollbar-w
    .ht_clone_top
    .wtHolder {
    width: 100% !important;
  }

  .sheet-preview.not_firefox .handsontable .ht_clone_left,
  .sheet-preview.not_firefox .handsontable .ht_clone_left .wtHolder {
    height: 100% !important;
  }

  .sheet-preview.not_firefox .handsontable.add-space-scrollbar-h .ht_clone_left,
  .sheet-preview.not_firefox
    .handsontable.add-space-scrollbar-h
    .ht_clone_left
    .wtHolder {
    height: calc(100% - 4px) !important;
  }

  .sheet-preview #hotInstance .ht_clone_top_left_corner,
  .sheet-preview #hotInstance .ht_clone_left td {
    box-shadow: 2px 0 5px -2px hsl(0deg 0% 53% / 30%);
  }

  .sheet-preview .hide-border-last-column .ht_clone_top th:last-child,
  .sheet-preview .hide-border-last-column .ht_clone_top td:last-child,
  .sheet-preview
    div.hide-border-last-column
    .handsontable.ht_master
    tr
    td:last-child {
    border-right-color: transparent !important;
    border-right-width: 0 !important;
  }

  .sheet-preview tr td.default-cell,
  .sheet-preview tr td.hover-row {
    @apply text-blue-dark-900 cursor-pointer text-sm;
  }

  .sheet-preview.read-only tr td.default-cell {
    @apply text-gray-560;
  }

  .sheet-preview.read-only tr td {
    @apply !cursor-auto;
  }

  .sheet-preview
    .ht_clone_left
    .wtHolder
    .wtHider
    div.wtSpreader
    .htCore
    tbody
    tr
    th {
    text-indent: -1px;
  }

  .sheet-preview .ht_master .wtHolder .wtSpreader table {
    width: 100%;
  }

  .sheet-preview .ht_left .wtHolder .wtSpreader {
    width: 100%;
  }

  .sheet-preview tr .hover-row,
  .sheet-preview tr .hover-selected-row,
  .sheet-preview tr .selecting-row {
    @apply cursor-pointer text-sm;
  }

  .sheet-preview tr td div {
    padding-top: 7px;
    box-sizing: border-box;
  }

  .sheet-preview .handsontable tr td.selecting-row-and-hovering.selecting-row {
    @apply text-blue-dark-900 cursor-pointer;
  }

  .sheet-preview .handsontable.wt-full-width .ht_master.handsontable .wtHider {
    width: 100% !important;
  }

  .sheet-preview
    .handsontable.wt-full-width
    .ht_master.handsontable
    .wtHider
    .wtSpreader {
    width: 100% !important;
  }

  /* join-sheet-preview */

  .join-sheet-preview #hot-display-license-info {
    display: none;
  }

  .join-sheet-preview .handsontable .htBorders {
    display: none;
  }

  .join-sheet-preview .handsontable {
    font-family: var(--globals-font-family);
  }

  .join-sheet-preview .handsontable td .ht-cell {
    padding: 0px 16px;
  }

  .join-sheet-preview .handsontable td {
    padding: 0px 16px !important;
    border-color: #dddddd;
    @apply items-center !text-left;
  }

  .join-sheet-preview
    .handsontable
    .hide-border-last-row
    .htCore
    tr:last-child
    td {
    @apply border-b-0;
  }

  .join-sheet-preview
    .handsontable
    .hide-border-last-row
    .ht_clone_left
    tr:last-child
    th {
    border-bottom: transparent;
  }

  .join-sheet-preview .handsontable .ht_master .wtHolder::-webkit-scrollbar {
    @apply !h-2 !w-2;
  }

  .join-sheet-preview
    .handsontable
    .ht_master
    .wtHolder::-webkit-scrollbar-track {
    background-color: #dfe2e4;
    @apply rounded-medium !w-1 bg-clip-padding;
  }

  .join-sheet-preview
    .handsontable
    .ht_master
    .wtHolder::-webkit-scrollbar-thumb {
    @apply rounded-medium !bg-gray-300;
  }

  .join-sheet-preview ::-webkit-scrollbar-thumb {
    background-color: #818b99;
    border: 0px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
  }

  .join-sheet-preview .ht_clone_left .wtHolder::-webkit-scrollbar {
    @apply !h-2 !w-2;
  }

  .join-sheet-preview .ht_clone_left .wtHolder::-webkit-scrollbar-track {
    background-color: #dfe2e4;
    @apply rounded-medium !mr-2 bg-clip-padding;
  }

  .join-sheet-preview .ht_clone_left .wtHolder::-webkit-scrollbar-thumb {
    @apply rounded-medium !bg-gray-300;
  }

  .join-sheet-preview .ht_clone_left .wtHolder .wtHider {
    box-sizing: border-box !important;
    @apply !mb-8;
  }

  .join-sheet-preview.not_firefox .ht_clone_top {
    width: 100% !important;
  }

  .join-sheet-preview.not_firefox
    .handsontable.wt-full-width
    .ht_clone_top
    .wtHolder,
  .join-sheet-preview.not_firefox
    .handsontable.wt-full-width
    .ht_clone_top
    .wtHolder
    .wtHider,
  .join-sheet-preview.not_firefox
    .handsontable.wt-full-width
    .ht_clone_top
    .wtHolder
    .wtSpreader,
  .join-sheet-preview.not_firefox
    .handsontable.wt-full-width
    .ht_clone_top
    .wtHolder
    .htCore {
    width: 100% !important;
  }

  .join-sheet-preview.not_firefox
    .handsontable.add-space-scrollbar-w
    .ht_clone_top {
    width: calc(100% - 8px) !important;
  }

  .join-sheet-preview.not_firefox
    .handsontable.add-space-scrollbar-w
    .ht_clone_top
    .wtHolder {
    width: 100% !important;
  }

  .join-sheet-preview.not_firefox .handsontable .ht_clone_left,
  .join-sheet-preview.not_firefox .handsontable .ht_clone_left .wtHolder {
    height: 100% !important;
  }

  .join-sheet-preview.not_firefox
    .handsontable.add-space-scrollbar-h
    .ht_clone_left,
  .join-sheet-preview.not_firefox
    .handsontable.add-space-scrollbar-h
    .ht_clone_left
    .wtHolder {
    height: calc(100% - 4px) !important;
  }

  .join-sheet-preview #hotInstance .ht_clone_top_left_corner,
  .join-sheet-preview #hotInstance .ht_clone_left td {
    box-shadow: 2px 0 5px -2px hsl(0deg 0% 53% / 30%);
  }

  .join-sheet-preview .hide-border-last-column .ht_clone_top th:last-child,
  .join-sheet-preview .hide-border-last-column .ht_clone_top td:last-child,
  .join-sheet-preview
    div.hide-border-last-column
    .handsontable.ht_master
    tr
    td:last-child {
    border-right-color: transparent !important;
    border-right-width: 0 !important;
  }

  .join-sheet-preview tr td.default-cell {
    @apply text-blue-dark-900 text-sm;
  }

  .join-sheet-preview tr td.default-cell.nuvo-appended-cell {
    @apply bg-blue-light-50;
  }

  .join-sheet-preview
    .ht_clone_left
    .wtHolder
    .wtHider
    div.wtSpreader
    .htCore
    tbody
    tr
    th {
    text-indent: -1px;
  }

  .join-sheet-preview .ht_master .wtHolder .wtSpreader table {
    width: 100%;
  }

  .join-sheet-preview .ht_left .wtHolder .wtSpreader {
    width: 100%;
  }

  .join-sheet-preview tr td div {
    padding-top: 7px;
    box-sizing: border-box;
  }

  .join-sheet-preview .handsontable th .colHeader {
    width: 100%;
  }

  .join-sheet-preview
    .handsontable.wt-full-width
    .ht_master.handsontable
    .wtHider {
    width: 100% !important;
  }

  .join-sheet-preview
    .handsontable.wt-full-width
    .ht_master.handsontable
    .wtHider
    .wtSpreader {
    width: 100% !important;
  }

  .join-sheet-preview .handsontable span.colHeader {
    line-height: 1.2;
  }

  .htFocusCatcher {
    padding: 0;
  }
}
